import { CircularProgress, Pagination, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, Route } from "react-router-dom";
import swal from "sweetalert";
import { TicketNavbar } from "../../components/TicketNavbar/TicketNavbar";
import { Tickets } from "../../components/Tickets/Tickets";
import "./TicketPage.css";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { fetchTicketsListing } from "../../services/Ticket.services";

export const TicketPage = (props) => {
  const [upcomingTickets, setUpcomingTickets] = useState([]);
  const [expiredTickets, setExpiredTickets] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageUpcoming, setPageUpcoming] = useState(1);
  const [pageExpired, setPageExpired] = useState(1);
  const [rowsPerPage] = useState(10);

  const handleChange = (event, value) => {
    setPageUpcoming(value);
  };

  const handleChangeExpiredPage = (event, value) => {
    setPageExpired(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.changeLinks("tickets");
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    setLoader(true);
    const ticketsListing = async () => {
      try {
        const response = await fetchTicketsListing();
        const data = await response.data;
        if (response.status === 200) {
          setLoader(false);
          setUpcomingTickets(data.data.upcoming);
          setExpiredTickets(data.data.expired);
        }
        if (response.status === 401) {
          swal(data.message, "Please login again", "error");
          setTimeout(() => {
            window.localStorage.clear();
          }, 3000)
        }
      } catch (err) {
        console.log(err);
      }
    };
    ticketsListing();
  }, []);

  return (
    <>
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv2">
          <h1>MY TICKETS</h1>
        </div>
        <p style={{ color: "#fff", fontWeight: "bold" }}>For ticket inquiries or questions, please contact us at <a style={{ color: "#fff" }} href="mailto:Helpdesk@moontowertickets.com">Helpdesk@moontowertickets.com</a></p>
      </div>
      <div className="ticket_page_div_nav">
        <TicketNavbar />
      </div>
      <div className="ticket_page_div">
        <Route exact path="/tickets">
          <div className="tickets_sub_div">
            {upcomingTickets.length < 1 ? (
              <div className="no_tickets_div">
                {loader ? (
                  <CircularProgress
                    color="warning"
                    style={{ fontSize: "4rem" }}
                  />
                ) : (
                  <>
                    <h1>You have not purchased any tickets.</h1>
                    <NavLink to="/shows">
                      <button className="purchase_now_btn">Purchase Now</button>
                    </NavLink>
                  </>
                )}
              </div>
            ) : (
              <>
                {upcomingTickets.slice((pageUpcoming - 1) * rowsPerPage, pageUpcoming * rowsPerPage).map((ticket) => {
                  return <Tickets ticket={ticket} key={ticket.barcode} />;
                })}
                <Stack spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={2}>
                  <Pagination count={Math.ceil(upcomingTickets.length / rowsPerPage)} page={pageUpcoming} onChange={handleChange} color="secondary" />
                </Stack>
              </>
            )}
          </div>
        </Route>
        <Route exact path="/tickets/expiredtickets">
          {expiredTickets.length < 1 ? (
            <div className="no_tickets_div">
              <h1>Your tickets haven't expired yet.</h1>
              <NavLink to="/shows">
                <button className="purchase_now_btn">Purchase Now</button>
              </NavLink>{" "}
            </div>
          ) : (
            <>
              {expiredTickets.slice((pageExpired - 1) * rowsPerPage, pageExpired * rowsPerPage).map((ticket) => {
                return <Tickets ticket={ticket} key={ticket.barcode} />;
              })}
              <Stack spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={2}>
                <Pagination count={Math.ceil(expiredTickets.length / rowsPerPage)} page={pageExpired} onChange={handleChangeExpiredPage} color="secondary" />
              </Stack>
            </>
          )}
        </Route>
      </div>
    </>
  );
};

const mapStoreToProps = (state) => {
  return {
    shows: state.shows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushShows: (shows) =>
      dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink })
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(TicketPage);