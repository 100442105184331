  import "./App.css";
  import { BrowserRouter, Switch, Route } from "react-router-dom";
  import AppBar from "./components/Appbar/AppBar";
  import LoginPage from "./pages/MyAccount/LoginPage";
  import ShowsPage from "./pages/Shows/Shows";
  import { useEffect, useState } from "react";
  import { BackToTop } from "./components/BackToTop/BackToTop";
  import ShowPage from "./pages/ShowPage/ShowPage";
  import HomePage from "./pages/HomePage/HomePage";
  import { connect } from "react-redux";
  import TicketPage from "./pages/TicketsPage/TicketPage";
  import * as actionTypes from "./store/actions";
  import { Redirect } from "react-router-dom";
  import { Checkout } from "./pages/Checkout/Checkout";
  import { SuccessPage } from "./pages/SuccessPage/SuccessPage";
  import MyProfile from "./pages/MyAccount/MyProfile";
  import { Policy } from "./pages/TCP/Policy";
  import { TermAndCondition } from "./pages/TCP/TermAndCondition";
  import { RefundPolicy } from "./pages/TCP/RefundPolicy";
  import ProtectedRoutes from "./routes/ProtectedRoutes";
  import { NotFound } from "./pages/404/404";
  import { Verification } from "./pages/Verification/Verification";
  import { PaymentFailed } from "./pages/PaymentFailed/PaymentFailed";
  import  PromoterShowsPage  from "./pages/Promoter/PromoterShows";
  import { FooterData } from './pages/HomePage/Data';
  import Footer2 from './components/Comp/Footer/Footer';
  import Header from './components/Comp/Header/Header';	
import { DeleteAccount } from "./pages/Delete-Account/DeleteAccount";



  export const App = (props) => {

    let loggedIn = window.localStorage.getItem("token") && window.localStorage.getItem("inGuestMode") === "false"
    let registeredButNotLoggedIn = (window.localStorage.getItem("token") || window.localStorage.getItem("userId") || window.sessionStorage.getItem("userId"))

    const [top, setTop] = useState("-500vh");

    const pageScroll = () => {
      window.onscroll = function (ev) {
        if (window.scrollY > 300) {
          setTop("80vh");
        }
        else {
          setTop("-100%");
        }
      };
    };

    useEffect(() => {
      pageScroll();
      // eslint-disable-next-line
    }, [window.scrollY]);

    return (
      <>
        <BrowserRouter>
          {/* <Navbar /> */}
          <Header/>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/shows">
              <ShowsPage shows={props.shows} />
            </Route>
            <Route exact path="/promoters/:id">
              <PromoterShowsPage shows={props.shows} />
            </Route>
            <Route exact path="/login">
              {loggedIn ? (
                <Redirect to="/" />
              ) : (
                <LoginPage />
              )}
            </Route>
            <Route
              exact
              path="/showspage/:id"
              render={({ match }) => (
                <ShowPage
                  showId={match.params.id}
                  show={props.shows.find(
                    (show) => show.product_id === parseInt(match.params.id)
                  )}
                />
              )}
            />
            <ProtectedRoutes path="/tickets" isAuthenticated={loggedIn}>
              <TicketPage />
            </ProtectedRoutes>
            <ProtectedRoutes path="/successpayment" isAuthenticated={registeredButNotLoggedIn}>
              <SuccessPage shows={props.shows} />
            </ProtectedRoutes>
            <Route
              path="/checkout/:id/:ht"
              render={({ match }) =>
                <ProtectedRoutes
                  isAuthenticated={true}
                >
                  <Checkout
                    show={props.shows.find(
                      (show) => show.product_id === parseInt(match.params.id)
                    )}
                  />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/checkout/:id"
              render={({ match }) =>
                <ProtectedRoutes
                  isAuthenticated={true}
                >
                  <Checkout
                    show={props.shows.find(
                      (show) => show.product_id === parseInt(match.params.id)
                    )}
                  />
                </ProtectedRoutes>
              }
            />
            <ProtectedRoutes
              exact path="/myprofile"
              isAuthenticated={loggedIn}
            >
              <MyProfile />
            </ProtectedRoutes>
            <ProtectedRoutes
              exact path="/payment-failed"
              isAuthenticated={true}
            >
              <PaymentFailed />
            </ProtectedRoutes>
            <Route path="/user-account-delete" exact>
              {loggedIn ?
                <Redirect to="/myprofile" /> :
                <Redirect to="/login?account-delete=true" />
              }
            </Route>
            <Route path="/terms-and-conditions" exact>
              <TermAndCondition />
            </Route>
            <Route path="/account-delete" exact>
              <DeleteAccount />
            </Route>
            <ProtectedRoutes path="/user-activation" isAuthenticated={!loggedIn}>
              <Verification />
            </ProtectedRoutes>
            <Route path="/privacy-policy" exact>
              <Policy />
            </Route>
            <Route path="/refund-policy" exact>
              <RefundPolicy />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <AppBar />
          {/* <Footer /> */}
          <Footer2 {...FooterData}/> 
          <BackToTop top={top} />
        
        </BrowserRouter>
      </>
    );
  };

  const mapStoreToProps = (state) => {
    return {
      shows: state.shows,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      pushShows: (shows) =>
        dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    };
  };

  export default connect(mapStoreToProps, mapDispatchToProps)(App);


